html {
	height:100%;
}
body {
	font-size: 10pt;
	font-family: Arial;
	color: #333333;
	margin:0px;
	padding:0px;
	height:100%;
}
A:link {
	color: #333333;
	text-decoration: none;
}
A:visited {
	text-decoration: none;
	color:	#333333;
}
A:active {
	text-decoration: none;
	color:	#333333;
}
A:hover {
	color: #333333;
	text-decoration: underline;
}
IMG {
	margin-top: 0px;
	margin-left: 0px;
	margin-right: 0px;
	border: none;
}
Table {
	font-size: 9pt;
	overflow: hidden;
	font-family: Arial;
}
h1, h2, h3 {
	margin:0px;
}
h3 {
	font-size: 10pt;
	font-family: Arial;
	color: #4e6380;
	font-weight: bold;
}
h2.SectionHeader {
	font-size:12pt;
	border-bottom:solid 1px darkblue;
	margin-bottom:5px;
	margin-top:8px;
}
  html, body {
            height:100%;overflow:auto;
        }

           #jxLoading {
            position:absolute;width:100%;height:100%; background-color:rgba(255, 255, 255, 0.67); z-index:9000; 
            text-align:center; padding-top:150px;    display:none;
        }
              #jxInfoPanel {
            background-color:  #ee8e4a; color: white;
            padding: 5px 5px 7px 2px;
           box-shadow: 0px 5px 10px rgba(0,0,0,.2) inset; 
            position:absolute; top:-50px;
            width:100%; z-index: 500;
        }
                #jxInfoPanel .ui-icon {
                    float:left;
                    cursor:pointer;
                }
        #jxInfoLink {
            text-decoration:underline;
            color: #3839f6;
            cursor:pointer;
        }
        .InfoContent {
            padding:10px;
        }

/*Portal CSS */      
.PortalMenuItem {
	color: #4e6380;
	font-family: Arial;
	font-size: 10pt;
	background-repeat:no-repeat;
	margin-bottom: 20px;
}
TD.PortalMenuItem {
	height: 40px;
	width: 171px;
	margin-bottom: 20px;
}
.SideMenuBlock a {
	background-image: url(/images/multiimage.png);
	background-position: 0px -121px;
	display: block;
	background-repeat: no-repeat;
	height: 20px;
	padding-left: 10px;
	padding-top: 2px;
	margin-bottom: 10px;
}
/* End Portal CSS*/

.MenuItem {
	vertical-align: middle;
	text-decoration: none;
	width: 150px;
	border-right: #e0e2eb 1px solid;
	border-top: #e0e2eb 1px solid;
	border-left: #e0e2eb 1px solid;
	border-bottom: #4e6380 1px solid;
	background-color: #e0e2eb;
}
.MenuItem:hover {
	border-right: #d09f34 1px solid;
	border-top: #d09f34 1px solid;
	border-left: #d09f34 1px solid;
	border-bottom: #d09f34 1px solid;
	background-color: #fdf1a6;
	text-decoration: none;
}
.MenuSelected {
	height: 20px;
	width: 150px;
	vertical-align: middle;
	background-color: #A3B3C3;
	text-decoration: none;
	font-weight: bold;
}
/* These classes control the left and right columns*/


.sminfotext {
	font-size:8pt;
	color:Gray;
}
.MiniLink {
	font-size: 7pt;
	font-family: Arial;
	color: Blue;
    cursor:pointer;
}
.MiniLink:Link {
	color: Blue;
}
.MiniLink:Hover {
	color: Blue;
}
.MiniLink:Visited {
	color: Blue;
}
.minilink {
	font-size: 7pt;
	font-family: Arial;
	color: Blue;
    cursor: pointer;
}
.minilink:Link {
	color: Blue;
}
.minilink:Hover {
	color: Blue;
}
.minilink:Visited {
	color: Blue;
}
.DisplayLink {
	font-size: 9pt;
	font-family: Arial;
	color: #4e6380;
	text-decoration: underline;
	overflow:hidden;
}
.DisplayLink:Link {
	color: #4e6380;
	text-decoration: underline;
	overflow:hidden;
}
.DisplayLink:Hover {
	color: #4e6380;
	text-decoration: underline;
	overflow:hidden;
}
.DisplayLink:Visited {
	color: #4e6380;
	text-decoration: underline;
	overflow:hidden;
}
.DisplayLink1 {
	font-size: 9pt;
	font-family: Arial;
	color:  silver;
	text-decoration: underline;
	overflow:hidden;
}
.DisplayLink1:Link {
	color: silver;
	text-decoration: underline;
	overflow:hidden;
}
.DisplayLink1:Hover {
	color: silver;
	text-decoration: underline;
	overflow:hidden;
}
.DisplayLink1:Visited {
	color: silver;
	text-decoration: underline;
	overflow:hidden;
}
.MenuLink {
	font-size: 9pt;
	font-family: Arial;
	color: black;
	/*text-decoration: underline;*/
}
.MenuLink:Link {
	color: black;
	/*text-decoration: underline;*/
}
.MenuLink:Hover {
	color: black;
	text-decoration: underline;
}
.MenuLink:Visited {
	color: black;
	/*text-decoration: underline;*/
}
.BodyTable {
	border-right: lightgrey 1px solid;
	border-top: #a3b3c3 3px solid;
	border-left: lightgrey 1px solid;
	border-bottom: #a3b3c3 3px solid;
}
.BoxedLB {
	border-bottom: lightgrey 1px solid;
	border-left: lightgrey 1px solid;
}
.SecondaryMenuItem {
	background-image: url(/images/multiimage.png);
	overflow: visible;
	color: Black;
	font-family: Arial;
	font-size: 9pt;
	text-decoration: none;
	vertical-align: middle;
	height: 20px;
	width: 129px;
	padding-top: 3px;
	padding-left: 10px;
	padding-bottom:5px;
	background-repeat: no-repeat;
	display:block;
	background-position: 0px -746px;
}
.SecondaryMenuItem:hover {
	background-position: 0px -776px;
	text-decoration: none;
	color: Black;
	cursor:pointer;
}
.TabSelectedBody {
	background-image: url(/images/tabimages/tab_sel_body.bmp);
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
}
.TabSelectedLeft {
	background-image: url(/images/tabimages/tab_sel_left.bmp);
	width: 10px;
}
.TabSelectedRight {
	background-image: url(/images/tabimages/tab_sel_right.bmp);
	width: 18px;
}
.TabSelectedRightOverlap {
	background-image: url(/images/tabimages/tab_sel_right_overlap.bmp);
	width: 19px;
}
.TabBackBody {
	background-image: url(/images/tabimages/tab_back_body.bmp);
	vertical-align: middle;
	text-align: center;
}
.TabBackLeft {
	background-image: url(/images/tabimages/tab_back_left.bmp);
	width: 8px;
}
.TabBackRight {
	background-image: url(/images/tabimages/tab_back_right.bmp);
	width: 15px;
}
.TabBackRightOverlap {
	background-image: url(/images/tabimages/tab_back_right_overlap.bmp);
	width: 17px;
}
.TabBackRightUnderlap {
	background-image: url(/images/tabimages/tab_back_right_underlap.bmp);
	width: 17px;
}
.TabBody {
	background-image: url(/images/tabimages/tab_body.bmp);
}
.HLinkButton {
	width: 122px;
	height: 19px;
	text-align: center;
	vertical-align: middle;
	font-weight: normal;
	background-image: url(/Images/linkbutton.gif);
}
.HLinkButton:hover {
	background-image: url(/Images/linkbutton1.gif);
	cursor: crosshair;
}



/* Header  */
.headerbar {
	background-image: url(/images/header-background.jpg);
	background-repeat:repeat-x;
	height:70px;
margin-bottom: 10px;
}

/* The Title Bar */
.SecondRow {
	height:30px;
	overflow:hidden;
	margin-left:160px;
}
.sectiontitle {
	color:#506482;
	font-family:Arial;
	font-size:16px;
	font-weight:normal;
	position:absolute;
	left:190px;
	top: 5px;
}

/*
.memberoptionsbar {
	text-align:right;
	height:27px;
	overflow:hidden;
	margin-left:160px;
}
.memberoptionsbar a {
	font-size:8pt;
	color:White;
}
*/
.changegrouplink {
 color: #506482;
 font-size: 9px;
}
.changememberlink {
	color:#506482;
    font-size: 10px;
}
.ChangeClientBlock {
	padding: 30px 10px 0px 0px;

		position:absolute;
	left:190px;
top: 16px;
}
.ChangeClientBlock input {
	font-size:8pt;
	margin-top: 1px;
}
.ChangeClientBlock a.goSearch 
{
    border: solid 1px #CCC;
    background-image: url(/images/backgrounds/goSearch_bg.png);
	background-repeat:repeat-x;
	padding-right: 12px;
	padding-left: 12px;
	padding-top: 3px;
	padding-bottom: 3px;
	height: 15px;
	line-height: 15px;
	color: #FFF;
	font-size: 9px;
	text-decoration: none;
}

.ChangeClientBlock a.goAdvanced 
{
    color: #991B1E;
    font-size: 10px;
}


.CurrencyAlign {
	text-align:right;
    font-size: 10px;
    color: #666;
}

/* This was a shadow, now deprecated*/
.sectiontitle span {
	color:#F8ECC2;
	display:inline;
	position:absolute;
	left:213px;
	top:25px;
	visibility: hidden;
}
.sidecolumn {
	vertical-align:top;
}
.maincontent {
	vertical-align:top;
	padding:0px 0px 0px 5px;
	width:100%;
}
/* This contains a graph*/
.gcontainer {
	background-color:#f0f2fb;
	border-right: #03495d 1px solid;
	border-top: #03495d 1px solid;
	border-left: #03495d 1px solid;
	border-bottom: #03495d 1px solid;
}
.arow {
    background-color:#e7eeef;
}
.dtable {
	border-collapse: collapse;
}
.dtable thead {
	background-color:#03495d;
	font-weight:bold;
	color:White;
}
.dtable thead th {
	background-color:#03495d;
	font-weight:bold;
	color:White;
	text-align:left;
}
.dtable tfoot th,
.dtable tfoot td {
  padding: 10px !important;
}
.dataTables_scrollBody .tfoot{
    display: none;
}
.dtable tr.hrow td {
	background-color:#03495d;
	font-weight:bold;
	color:White;
}
.dtable tr.prow td, .dtable tr.odd td {
	font-size:8pt;
	border-bottom:solid 1px silver;
}
.dtable tr.nrow td {
	font-size:8pt;
	border-bottom:none;
}
.dtable tr.arow td, .dtable tr.even td {
	background-color: #e0e2eb;
	font-size:8pt;
	border-bottom:solid 1px silver;
}
.dtable tr.SummaryRow td {
	border-top: solid 1px black;
}
.dtable tr.prow input {
	font-size:8pt;
}
.dtable tr.arow input {
	font-size:8pt;
}
.dtable .SectionHeader1 {
	background-color:#b8c5d3;
	font-weight:bold;
}
.dtable .SectionHeader2 {
	background-color:#ffffc6;
	font-weight:bold;
}
.dtable .drow td {
	font-size: 8pt;
	font-style:italic;
	color:Gray;
} /*detail row*/
.dtable .drowlast td {
	font-size: 8pt;
	font-style:italic;
	color:Gray;
	border-bottom:solid 1px silver;
} /*detail row*/
.dtable .rowoptions a, .dtable .rowoptions a:link, .dtable .rowoptions a:active, .dtable .rowoptions a:visited {
	font-size: 7pt;
	font-family: Arial;
	color: Blue;
}
.dtable .rowoptions a:hover {
	color: red;
	cursor: pointer;
}
.dtable thead a {
	color:White;
}
.dtable thead a:hover {
	color:yellow;
}
.dtable .arow {
	background-color:#e7eeef;
}


.dtable .titlerow {
	font-weight:bold;
}
.importanttext {
	color:Red;
}
.sortable thead td {
	cursor: pointer;
}
.sortable thead th {
	cursor: pointer;
}
.contentseccompany {
	font-size: 10pt;
	font-weight:bold;
}
.contentsecdba {
	color: #00008B;
	font-size:7pt;
	font-weight:bold;
}
.contentseparator {
	height:4px;
	background-color: #4e6380;
	text-align:left;
}
.tabcontentareaprimary {
	overflow:auto;
}
.tabcontentareasecondary {
	overflow: auto;
	border: #0653a0 1px outset;
	background-color:#fcfdf6
}
.optionlink a:link, .optionlink a:active, .optionlink a:visited {
	color:#00008B;
	font-size:7pt;
}
.optionlink a:hover {
	color:Red;
}

.LongText {
    width:250px;
}
.AlignRight {
   float:right;
}
input.watermark, textarea.watermark { color: #000; }
input.marked, textarea.marked { color: #999; }
.watermark
.appoptionbutton:link, .appoptionbutton:active, .appoptionbutton:visited, .appoptionbutton {
	background-image: url(/Images/Button3.gif);
	overflow: visible;
	color: Black;
	font-family: Arial;
	font-size: 9pt;
	text-decoration: none;
	vertical-align: middle;
	height: 20px;
	width: 135px;
	margin-top: 5px;
	padding-top:2px;
	padding-left: 10px;
	padding-bottom:1px;
	background-repeat: no-repeat;
	display:block;
	float:left;
}
.appoptionbutton:hover {
	background-image: url(/Images/Button3a.gif);
	text-decoration: none;
	color: Black;
	cursor:pointer;
}
.widget_dropcue {
	border-right: black 2px dotted;
	border-top: black 2px dotted;
	border-left: black 2px dotted;
	border-bottom: black 2px dotted;
	display:none;
}
.widget_header {
	cursor:move;
	display:inline;
	vertical-align: text-bottom;
}
.readonlybox {
	background-color: #fbfaf5;
	border: dashed 1px silver;
	color:#294e5e;
}
.formlabel {
	font-weight:bold;
}
.cleaneditbox {
	background-color:#f3f3f6
}
.watermarked {
	font-family: Times New Roman;
	color:#947339;
	background-color:#f3f3f6;
}
/* its common to have a bunch of buttons next to each other, save, cancel, delete, etc*/
.CommandRow input {
	margin-right:25px;
}
/* An example is a subheadering in a form*/
.SectionSeparator td {
	border-bottom:inset 2px #aca899;
	color:#e1691f;
	font-weight:bold;
}
/*      Oblic overlapping tabs   These are pure HTML/CSS tabs    */
ul.obtabs {
	list-style: none;
	margin: 1px 0 -1px 0;
	padding: 0;
	position: absolute;
}
ul.obtabs li {
	float: left;
	display: block;
	height: 24px;
	padding-right: 12px;
	margin-left: -5px;
	position: relative;
	/*background: url(/images/tabright-back.gif) 100% 0 no-repeat;    */
              background-image: url(/images/multitabs.png);
	background-repeat:no-repeat;
	background-position: 100% 0px;
	border-bottom: 1px solid #bbb8a9;
	white-space: nowrap;
}
.obtabs li:first-child {
	margin-left:0px;
}
ul.obtabs span {
	height: 24px;
	line-height: 24px;
	padding-left: 7px;
	background-image: url(/images/multitabs.png);
	background-repeat:no-repeat;
	background-position: 0 -73px;
}
html>body ul.obtabs span {
	display: block;
}
ul.obtabs li.current {
	z-index: 1;
	font-weight: bolder;
	border-bottom: 1px solid #fff;
	height: 24px;
	background-image: url(/images/tabright.gif);
}
ul.obtabs li.current span {
	background-image: url(/images/multitabs.png);
	background-repeat:no-repeat;
	background-position: 0 -121px;
	height:25px;
}
ul.obtabs a {
	color: #4e6380;
    font-size:Smaller;
}
ul.obtabs li:hover {
	background-image: url(/images/multitabs.png);
	background-repeat:no-repeat;
	background-position: 100% -24px;
}
ul.obtabs li:hover span {
	background-image: url(/images/multitabs.png);
	background-repeat:no-repeat;
	background-position: 0 -97px;
}
ul.obtabs li.current:hover {
	background-image: url(/images/multitabs.png);
	background-repeat:no-repeat;
	background-position: 100% -48px;
}
ul.obtabs li.current:hover span {
	background-image: url(/images/multitabs.png);
	background-repeat:no-repeat;
	background-position: 0 -121px;
}
ul.obtabs li:hover a {
	color:Black;
	cursor:  pointer;
	text-decoration:none;
}
ul.obtabs li.current:hover a {
	color: #4e6380;
}
ul.obtabs li a {
	width:90px;
	display:block;
}
/* AJAX Tabs */

.TabContainer .ajax__tab_header {
	margin: 1px 0 -1px 0;
	height:25px;
	padding-left:5px;
}
.TabContainer .ajax__tab_body {
	border:solid 1px #bbb8a9;
	padding:5px;
}
/* Removing this because it causes problems
.TabContainer span { display: block;}
*/
.TabContainer .ajax__tab_outer {
	float: left;
	display: block;
	height: 24px;
	padding-right: 12px;
	margin-left: -5px;
	position: relative;
	background: url(/images/tabright-back.gif) 100% 0 no-repeat;
	border-bottom: 1px solid #bbb8a9;
	white-space: nowrap;
}
.TabContainer .ajax__tab_inner {
	height: 24px;
	line-height: 24px;
	padding-left: 7px;
	background: url(/images/tableft-back.gif) no-repeat;
}
.TabContainer .ajax__tab_tab {
}
.TabContainer .ajax__tab_hover .ajax__tab_outer {
	background-image: url(/images/tabright-back_hover.gif);
}
.TabContainer .ajax__tab_hover .ajax__tab_inner {
	background-image: url(/images/tableft-back_hover.gif);
}
.TabContainer .ajax__tab_hover .ajax__tab_tab {
	color:Black;
}
.TabContainer .ajax__tab_active {
}
.TabContainer .ajax__tab_active .ajax__tab_outer {
	z-index: 1;
	font-weight: bolder;
	border-bottom: 1px solid #fff;
	height: 24px;
	background-image: url(/images/tabright.gif);
	margin-bottom:-1px;
}
.TabContainer .ajax__tab_active .ajax__tab_inner {
	background-image: url(/images/tableft.gif);
	height:25px;
}
.TabContainer .ajax__tab_active .ajax__tab_tab {
}
/* calculation forms */
.CalcTable thead td {
	border-bottom: solid 2px #CCC;
	font-weight:bold;
}
.CalcTable .SubHeading td {
	border-bottom: solid 2px #CCC;
	font-weight:bold;
}
.CalcTable td input {
	text-align:right;
	font-size:8pt;
}
.CalcTable td select {
	font-size:8pt;
}
.CalcTable td {
	font-size:8pt;
}
.SideMenu {
	margin-top:45px;
	margin-left:10px;
}
.linkoption {
	font-size:8pt;
}
.linkoption a:active, .linkoption a:link, .linkoption a:visited {
	color:Blue;
	font-size:8pt;
}
.linkoption a:hover {
	color:Red;
}
.StatusMessage {
	font-weight:bold;
	color: #3290d2;
}
/*  Ajax Calendar Appearance */
.ajax__calendar_day {
	background-color:white;
	border:none;
}
.ajax__calendar_days {
	border:none;
}
.ajax__calendar_body {
	border:none;
}
.ajax__calendar_container {
	border:none;
}
.ajax__calendar {
	border:none;
	z-index: 99999;
	position:absolute;
}
.ajax__calendar {
	border:none;
}
.ajax__calendar_header {
	border:none;
}
.ajax__calendar_title {
	border:none;
}
.ajax__calendar_dayname {
	border:none;
}
.ajax__calendar_other {
	border:none;
}
.ajax__calendar_body td {
	border-right: none;
	border-left: none;
	border-top: none;
	border-bottom: none;
}
.DialogPanel {
	background-color: #F1EFE2;
	border: darkgray 1px solid;
	overflow: hidden;
}
.ShadowRight {
	background-image: url(/images/dialog/pagebgright.jpg);
	width: 10px;
	background-repeat: repeat-y;
	vertical-align: top;
}
.ShadowBottomLeft {
	float: left;
	background-image: url(/Images/dialog/pagebgbottomleft.jpg);
	width: 10px;
	background-repeat: no-repeat;
	height: 10px
}
.ShadowBottom {
	background-image: url(/images/dialog/pagebgbottomgray.jpg);
	background-repeat: repeat-x;
	height: 10px
}
.ShadowBottomRight {
	float: right;
	background-image: url(/images/dialog/pagebgbottomright.jpg);
	width: 10px;
	background-repeat: no-repeat;
	height: 10px
}
.ShadowTopRight {
	background-image: url(/images/dialog/pagebgtopright.jpg);
	width: 10px;
	background-repeat: no-repeat;
	height: 10px
}
.DialogHeadLeft {
	background-image: url(/images/dialog/dialog1_left.gif);
	width: 21px;
	height: 21px;
	background-repeat: no-repeat;
}
.DialogHead {
	background-image: url(/images/dialog/dialog1_field.gif);
	background-repeat: repeat-x;
	text-align: center;
	font-weight: bold;
	font-size: 10pt;
	font-family: Arial;
}
.DialogHeadRight {
	background-image: url(/images/dialog/dialog1_right.gif);
	width: 21px;
	height: 21px;
	background-repeat: no-repeat;
}
.DialogHeadRightClose {
	background-image: url(/images/dialog/dialog1_rightclose.gif);
	width: 21px;
	height: 21px;
	background-repeat: no-repeat;
}
/**
 * POPUP CONTAINER STYLES
 * Why are there so many???  Simple, because different tools required different groups of css class.  I want to redo them, but as no one probably cares atm that the css file is a bit large since we're on an intranet, I'll fix it later.  But I "will" fix it. ;)  -Richard
 */
#popupMask {
	position: absolute;
	z-index: 200;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: .4;
	_filter: alpha(opacity=40);
	/* this hack is so it works in IE
	 * I find setting the color in the css gives me more flexibility 
	 * than the PNG solution.
	 */
	background-color:transparent !important;
	background-color: #ffffff;
	/* this hack is for opera support
	 * you can uncomment the background-image if you don't care about opera.
	 * this gives you the flexibility to use any bg color that you want, instead of the png
	 */
	background-image/**/: url("/images/maskBG.png") !important; /*// For browsers Moz, Opera, etc.*/
	background-image:none;
	background-repeat: repeat;
	display:none;
}
#popupContainer {
	position: absolute;
	z-index: 201;
	top: 0px;
	left: 0px;
	display:none;
	padding: 0px;
}
#popupInner {
	background-color:#A9A9A9;
}
#popupOuterTitle {
	background-image: url(/images/Dialog1_left.gif);
	background-repeat:no-repeat;
	background-position:left;
	padding-left:20px;
	height: 21px;
}
#popupInnerTitle {
	height: 20px;
	background-image: url(/images/Dialog1_RightClose.gif);
	background-repeat:no-repeat;
	background-position:right;
	padding-right: 0px;
}
#popupTitleBar {
	height: 20px;
	color: black;
	font-weight: bold;
	background-image: url(/images/Dialog1_Field.gif);
	background-repeat:repeat-x;
	position: relative;
	z-index: 203;
}
#popupTitle {
	float:left;
	font-size: 1.1em;
	padding: 2px 0px 0px 5px;
}
#popupControls {
	float: right;
	cursor: pointer;
	
	padding: 0px 0px 0px 0px;
}
#popupContent {
	border-left: darkgray 1px solid;
	border-Right: darkgray 1px solid;
	border-Bottom: darkgray 1px solid;
	background-color: #f1efe2;
	padding:5px;
}
.modalPopup {
	background-color:#ffffdd;
	border-width:3px;
	border-style:solid;
	border-color:Gray;
	padding:3px;
	width:250px;
}
/* Here is the code for Inline styles that is nearly identical to the code immediately above it */
.InlinePopupContainer {
	position: fixed;
	z-index: 200;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	/* Commented out below bc it causes the opacity of the popup to be incorrect in Safari (iPad)\
     *and IE9
     */
	/*opacity: .4;*/
	_filter: alpha(opacity=40);
	/* this hack is so it works in IE
	 * I find setting the color in the css gives me more flexibility 
	 * than the PNG solution.
	 */
	background-color:transparent !important;
	background-color: #ffffff;
	/* this hack is for opera support
	 * you can uncomment the background-image if you don't care about opera.
	 * this gives you the flexibility to use any bg color that you want, instead of the png
	 */
	background-image/**/: url("/images/maskBG.png") !important; /*// For browsers Moz, Opera, etc.*/
	background-image:none;
	background-repeat: repeat;
}
.InlinePopupExternal {
	BORDER-LEFT: gray thin;
	width:800px;
	margin-left:auto;
	margin-right:auto;
	BACKGROUND-COLOR: #dcdcdc;
	margin-top:50px;
	margin-left:inherit;
}
.InlinePopupTitleExt {
	background-image: url(/images/Dialog1_left.gif);
	background-repeat:no-repeat;
	padding-left:21px;
}
.InlinePopupTitleInt {
	color: black;
	font-weight: bold;
	background-image: url(/images/Dialog1_Field.gif);
	background-repeat:repeat-x;
}
.InlinePopupTitleBar {
	background-image: url(/images/Dialog1_RightClose.gif);
	background-repeat:no-repeat;
	background-position:right;
	padding-right: 0px;
}
.InlinePopupTitleBarExpand {
	background-image: url(/images/dialog/Dialog1_RightCloseExpand.GIF);
	background-repeat:no-repeat;
	background-position:right;
	padding-right: 0px;
}
.InlinePopupTitle {
	font-size: 1.1em;
	padding: 2px 0px 3px 5px;
}
.InlinePopupContent {
	border-left: darkgray 1px solid;
	border-Right: darkgray 1px solid;
	border-Bottom: darkgray 1px solid;
	background-color: #f1efe2;
	padding:5px;
}
/*Dups for second?*/




/**
 * POPUP CONTAINER STYLES
 * Why are there so many???  Simple, because different tools required different groups of css class.  I want to redo them, but as no one probably cares atm that the css file is a bit large since we're on an intranet, I'll fix it later.  But I "will" fix it. ;)  -Richard
 */
#popupMaskSS {
	position: absolute;
	z-index: 200;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: .4;
	_filter: alpha(opacity=40);
	/* this hack is so it works in IE
	 * I find setting the color in the css gives me more flexibility 
	 * than the PNG solution.
	 */
	background-color:transparent !important;
	background-color: #ffffff;
	/* this hack is for opera support
	 * you can uncomment the background-image if you don't care about opera.
	 * this gives you the flexibility to use any bg color that you want, instead of the png
	 */
	background-image/**/: url("/images/maskBG.png") !important; /*// For browsers Moz, Opera, etc.*/
	background-image:none;
	background-repeat: repeat;
	display:none;
}
#popupContainerSS {
	position: absolute;
	z-index: 201;
	top: 0px;
	left: 0px;
	display:none;
	padding: 0px;
}
#popupInnerSS {
	background-color:#A9A9A9;
}
#popupFrameSS {
	margin: 0px;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 202;
}
#popupOuterTitleSS {
	background-image: url(/images/Dialog1_left.gif);
	background-repeat:no-repeat;
	background-position:left;
	padding-left:20px;
	height: 21px;
}
#popupInnerTitleSS {
	height: 20px;
	background-image: url(/images/Dialog1_RightClose.gif);
	background-repeat:no-repeat;
	background-position:right;
	padding-right: 0px;
}
#popupTitleBarSS {
	height: 20px;
	color: black;
	font-weight: bold;
	background-image: url(/images/Dialog1_Field.gif);
	background-repeat:repeat-x;
	position: relative;
	z-index: 203;
}
#popupTitleSS {
	float:left;
	font-size: 1.1em;
	padding: 2px 0px 0px 5px;
}
#popupControlsSS {
	float: right;
	cursor: pointer;
	
	padding: 0px 0px 0px 0px;
}
#popupContentSS {
	border-left: darkgray 1px solid;
	border-Right: darkgray 1px solid;
	border-Bottom: darkgray 1px solid;
	background-color: #f1efe2;
	padding:5px;
}
.modalPopupSS {
	background-color:#ffffdd;
	border-width:3px;
	border-style:solid;
	border-color:Gray;
	padding:3px;
	width:250px;
}
/* Here is the code for Inline styles that is nearly identical to the code immediately above it */
.InlinePopupContainerSS {
	position: fixed;
	z-index: 200;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: .4;
	_filter: alpha(opacity=40);
	/* this hack is so it works in IE
	 * I find setting the color in the css gives me more flexibility 
	 * than the PNG solution.
	 */
	background-color:transparent !important;
	background-color: #ffffff;
	/* this hack is for opera support
	 * you can uncomment the background-image if you don't care about opera.
	 * this gives you the flexibility to use any bg color that you want, instead of the png
	 */
	background-image/**/: url("/images/maskBG.png") !important; /*// For browsers Moz, Opera, etc.*/
	background-image:none;
	background-repeat: repeat;
}
.InlinePopupExternalSS {
	BORDER-LEFT: gray thin;
	margin-left:auto;
	margin-right:auto;
	BACKGROUND-COLOR: #dcdcdc;
	margin-top:50px;
}
.InlinePopupTitleExtSS {
	background-image: url(/images/Dialog1_left.gif);
	background-repeat:no-repeat;
	padding-left:21px;
}
.InlinePopupTitleIntSS {
	color: black;
	font-weight: bold;
	background-image: url(/images/Dialog1_Field.gif);
	background-repeat:repeat-x;
}
.InlinePopupTitleBar {
	background-image: url(/images/Dialog1_RightClose.gif);
	background-repeat:no-repeat;
	background-position:right;
	padding-right: 0px;
}
.InlinePopupTitleBarExpand {
	background-image: url(/images/dialog/Dialog1_RightCloseExpand.GIF);
	background-repeat:no-repeat;
	background-position:right;
	padding-right: 0px;
}
.InlinePopupTitleSS {
	font-size: 1.1em;
	padding: 2px 0px 3px 5px;
}
.InlinePopupContentSS {
	border-left: darkgray 1px solid;
	border-Right: darkgray 1px solid;
	border-Bottom: darkgray 1px solid;
	background-color: #f1efe2;
	padding:5px;
}
/*Dups for second*/
 
 
/* Helper Classes, can be used on multiple sites*/
.clear {
	clear: both;
	overflow: hidden;
	width: 1px;
	height: 1px;
	margin: 0 -1px -1px 0;
	border: 0;
	padding: 0;
	font-size: 0;
	line-height: 0;
}
.left {
	float: left;
	display: inline; /* fixes ie double margin-left bug */
}
.right {
	float: right;
}
.note {
	width: 200px;
	border: 1px solid #666;
	padding: 10px;
	background: #ffc;
	color: #333;
}

.ScrollPanel {
	overflow:auto;

}
.ClippedText {
	overflow:hidden;
}
.ClippedTable {
	table-layout: fixed;
	overflow:hidden;
}
.TextAlignCenter {
	text-align: center
}
/* A css shadow block*/
.wrap1, .wrap2, .wrap3 {
	display:inline-table;
	/* \*/display:block;/**/
}
.wrap1 {
	float:left;
	background-color: #FFF;
	border: solid 1px #bbb8a9;
}
.wrap2 {
	background:url(/images/corner_bl.gif) left  bottom no-repeat;
}
.wrap3 {
    /*-webkit-box-shadow: Gray 5px 4px 10px;  \/*Outermost container with box shadow in SideTabBase*\/
    box-shadow: Gray 5px 4px 10px;*/
}
.wrap3 img {
	display:block;
}

.tabcontentareaprimary img {
	display: inline;
}
.wrap4 {
	background-color:#FFF;
	padding:8px;
}

  .ContainerBlock {
      box-shadow: 5px 5px 5px #888888; 
      padding-bottom:25px;
      border-right: solid 1px #bbb8a9; border-bottom: solid 1px #bbb8a9; border-left: solid 1px #bbb8a9; 
      padding-left:10px; padding-top: 10px;
      /* Note: This gradient may render differently in browsers that don't support the unprefixed gradient syntax */
       
/* IE10 Consumer Preview */ 
background-image: -ms-linear-gradient(bottom right, #ebecf2 0%, #FFFFFF 20%);

/* Mozilla Firefox */ 
background-image: -moz-linear-gradient(bottom right, #ebecf2 0%, #FFFFFF 20%);

/* Opera */ 
background-image: -o-linear-gradient(bottom right, #ebecf2 0%, #FFFFFF 20%);

/* Webkit (Safari/Chrome 10) */ 
background-image: -webkit-gradient(linear, right bottom, left top, color-stop(0, #ebecf2), color-stop(.2, #FFFFFF));

/* Webkit (Chrome 11+) */ 
background-image: -webkit-linear-gradient(bottom right, #ebecf2 0%, #FFFFFF 20%);

/* W3C Markup, IE10 Release Preview */ 
background-image: linear-gradient(to top left, #ebecf2 0%, #FFFFFF 20%);

        }

/* Side Tabs */
  
#SideTabContainer {
}
#SideTabContainer #tabs {
	position:relative;
	float:left;
	z-index:10;
	padding-top:10px;
	width:120px;
	height:100%;
    box-sizing: border-box;
}
#SideTabContainer #tabNavigation a {
	background:#eee;
	color:#444;
	width:119px;
	display:block;
	background:url(/images/side-tab-bg-off.png) no-repeat;
	border:none;
	cursor:pointer;
	padding:2px 0px 0px 10px;
	margin:0px 0px 5px 0px;
	font-size:11px;
	font-family:Arial;
	font-weight:normal;
	height:24px;
	letter-spacing:0px;
	text-align:left;
}
#SideTabContainer #tabNavigation a.activesubmit, #SideTabContainer #tabNavigation a.activesubmit:hover {
	background:#fff;
	font-weight:bold;
	width:120px;
	border-right:#fff 1px solid;
	border:none;
	color:#0033CC;
	background:url(/images/side-tab-bg.png) no-repeat;
	padding:2px 0px 0px 7px;
}
#SideTabContainer #tabNavigation a:hover {
	color:#0033CC;
	background:url(/images/side-tab-bg-hov.png) no-repeat;
	text-decoration:none;
	cursor: auto;
}
#SideTabContainer #tabformcontent {
	position:relative;
	float:left;
	padding:6px 10px;
	margin:0px 0px 0px -1px;
	border: #d2d2d4 1px solid;
	position:relative;
	
	min-height:200px;
	z-index:0;
}
#SideTabContainer #tabformcontent h1 {
	font-family:Arial;
	color:rgb(87, 87, 119);
	border-bottom:rgb(225, 225, 225) 2px solid;
	margin-bottom:4px;
	font-size:1.3em;
	text-align:right;
	padding-right:20px;
}
#SideTabContainer #tabformcontent p {
	margin:10px 2px 16px 2px;
}

#lstSources {   /*Changes Cursor to Pointer to correct iPad specific issue with changedatasource*/
    cursor: pointer;
}

/******* Modal Specific Classes and IDs *******/
.containerForModal {
    border: 1px solid #4e6380;                        
    border-radius: 4px;            
    width: 96.8%;
    padding: 5px;
    min-height: 150px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.containerForModalHover {                                
    -webkit-box-shadow: 0px 0px 5px #4e6380;
    box-shadow: 0px 0px 5px #4e6380;
}

#bottomButtonContainerForModal {
    margin-right: 10px;
    padding-top: 5px;
    float: right;
}
 
.dhtmlwindow {
	position: absolute;
	border: 1px solid gray;
	visibility: hidden;
	background-color: white;
}
.drag-handle { /*CSS for Drag Handle*/
	padding: 1px;
	text-indent: 3px;
	font: bold 14px Arial;
	background-color: #CA0000;
	color: white;
	cursor: move;
	overflow: hidden;
	width: auto;
filter:progid:DXImageTransform.Microsoft.alpha(opacity=100);
	-moz-opacity: 1;
	opacity: 1;
}
.drag-handle .drag-controls { /*CSS for controls (min, close etc) within Drag Handle*/
	position: absolute;
	right: 1px;
	top: 2px;
	cursor: pointer;
}
* html .drag-handle { /*IE6 and below CSS hack. Width must be set to 100% in order for opaque title bar to render*/
	width: 100%;
}
.drag-contentarea { /*CSS for Content Display Area div*/
	background-color: #A9A9A9;
	color: black;
	height: 150px;
	padding: 2px;
	overflow: auto;
}
.drag-statusarea { /*CSS for Status Bar div (includes resizearea)*/
	border-top: 1px solid gray;
	background-color: #F8F8F8;
	height: 13px; /*height of resize image*/
}
.drag-resizearea { /*CSS for Resize Area itself*/
	float: right;
	width: 13px; /*width of resize image*/
	height: 13px; /*height of resize image*/
	cursor: nw-resize;
	font-size: 0;
}
/*The following Classes are for Modal Dialogues */
.drag-handle { /*Overwrite default drag handle bar background color with below*/
	background-color: #03277C;
}
#interVeil { /*CSS for veil that covers entire page while modal window is visible*/
	position: absolute;
	background-color:transparent !important;
	background: white url(/images/maskBG.png);
	width: 10px;
	left: 0;
	top: 0;
	z-index: 5;
	visibility: hidden;
}
.CalImage {
	vertical-align:  top;
}
/* 
  -- Even more rounded corners with CSS: Base stylesheet --
*/

.Reddialog {
	position:relative;
	margin:0px auto;
	min-width:8em;
	max-width:760px; /* based on image dimensions - not quite consistent with drip styles yet */
	color:#000;
	z-index:1;
	margin-left:41px; /* default, width of left corner */
	margin-bottom:0.5em; /* spacing under dialog */
	display:none;
}
.Reddialog .innerdialog, .Reddialog .t, .Reddialog .b, .Reddialog .b div {
	background:transparent url(/images/dialog/RedBorderedPopup.png) no-repeat top right;
}
.Reddialog .innerdialog {
	position:relative;
	zoom:1;
	_overflow-y:hidden;
	padding:0px 41px 0px 0px;
}
.Reddialog .t {
	/* top+left vertical slice */
 position:absolute;
	left:0px;
	top:0px;
	width:41px; /* top slice width */
	margin-left:-41px;
	height:100%;
	_height:1600px; /* arbitrary long height, IE 6 */
	background-position:top left;
}
.Reddialog .b {
	/* bottom */
 position:relative;
	width:100%;
}
.Reddialog .b, .Reddialog .b div {
	height:40px; /* height of bottom cap/shade */
	font-size:1px;
}
.Reddialog .b {
	background-position:bottom right;
	width:100%;
}
.Reddialog .b div {
	position:relative;
	width:41px; /* bottom corner width */
	margin-left:-41px;
	background-position:bottom left;
}
.Reddialog .hd, .Reddialog .bd, .Reddialog .ft {
	position:relative;
}
.Reddialog .wrapper {
	/* extra content protector - preventing vertical overflow (past background) */
 position:static;
	max-height:1000px;
	overflow:auto; /* note that overflow:auto causes a rather annoying redraw "lag" in Firefox 2, and may degrade performance. Might be worth trying without if you aren't worried about height/overflow issues. */
}
.Reddialog .contentheader {
	padding:18px 0px 0px 0px;
	height:25px;
}
.Reddialog .contentheader h1 {
	font-size:15px;
	font-weight:bold;
	color:White;
	margin:0px;
	padding:0px;
	cursor:pointer;
}
.Reddialog h1, .Reddialog p {
	margin:0px; /* margins will blow out backgrounds, leaving whitespace. */
	padding:0.0em 0px 0.5em 0px;
}
.Reddialog h1 {
	padding-bottom:0px;
}
.tree * {
	margin:0;
	padding:0;
}
.tree li {
	list-style:none;
	padding-left:21px;
}
.tree li.root {
	padding-left:0;
}
.tree li li {
	background:url(/images/icons/rootNode.gif) no-repeat 17px center;
	margin-left:10px;
	padding-left:31px;
}
.tree li li.root {
	padding-left:10px;
	background:url(/images/icons/justOne.gif) repeat-y 17px 0;
}
.tree li li.root.last {
	background:none;
}
.tree li li.last {
	background:url(/images/icons/lastRoot.gif) no-repeat 17px 0;
}
.expand {
	background:url(/images/icons/plusMinus.gif) no-repeat;
	width:16px;
	_width:13px;
	height:16px;
	display:block;
	float:left;
	margin-top:2px;
	padding:0 5px 0 0;
	text-indent:-9999px;
	line-height:0;
	font-size:0;
}
.contract {
	background-position:0 -16px;
}
.expand:hover {
/*cursor:pointer;*/
	}
.AJAXTree {
	margin-left:0px;
}
.HiddenObject {
	position: absolute;
	left: -5000px;
}
.HideObject {
	display: none;
}
/* Status Icon*/
.statusicon {
	width:17px;
	height:17px;
		background-image: url(/images/status_icons.png);

	display:block;
}

.statusintheoffice {
	background-position: -0px -17px;

}

.statusclientvisit, .statusoffsitemeeting, .statustraveling {
	background-position: -18px -17px;
}

.statusunknown {
	background-position: -36px -17px;
}

.statusdonotdisturb, .statusoutofoffice {
	background-position: -54px -17px;
}

.statusouttolunch, .statussick,.statusvacation {
	background-position: -72px -17px;
}


/*
.statusicon {
	width:24px;
	height:24px;
	background-image: url(/images/multiimage.png);
	display:block;
}
.statusclientvisit {
	background-position: -24px -67px;
}
.statusdonotdisturb {
	background-position: -96px -67px;
}
.statusintheoffice {
	background-position: 0px -67px;
}
.statusoffsitemeeting {
	background-position: -24px -67px;
}
.statusoutofoffice {
	background-position: -72px -67px;
}
.statusouttolunch {
	background-position: -72px -67px;
}
.statussick {
	background-position: -72px -67px;
}
.statustraveling {
	background-position: -24px -67px;
}
.statusunknown {
	background-position: -48px -67px;
}
.statusvacation {
	background-position: -72px -67px;
}
*/

.CloseDialogButton {
	background-image: url(/images/multiimage.png);
	width:21px;
	height:21px;
	background-position: 0px -725px;
	cursor:pointer;
}
/* Project CSS */
    .statuscomplete {
	color:Green;
}
.statusonhold {
	color:orange;
}
.statusunassigned {
	color:Red;
}
.statuspending_start {
	color:#505072;
}
.AvatarImage {
	float:left;
}
.CTable td {
	vertical-align:top;
}
.CommBlock {
	clear:right;
}
.Author {
	font-weight:bold;
	color:#4559a2;
	font-size: larger;
}
.TimeStamp {
	font-size: smaller;
	color:Gray;
}
.Message td {
	border-bottom: solid 1px #e0e2eb;
}
.AlternateBackground {
	background-color:#e7eeef;
}
.MessageBody {
	min-height:35px;
}
.Reply td {
	border-top: solid 1px #e0e2eb;
	border-bottom: none;
}
.Navigation {
	text-align: right;
}
.MultiFile-list {
	padding: 5px 0px 10px 10px;
}
.MultiFile-label {
	color: #35354f;
}
.MultiFile-remove:link {
	color: red;
}
.multi {
	width:80px;
	margin-left:-5px;
}
.DisplayOnlyCell {
	border: solid 1px #d1dae3;
	color: #33393f;
}
.ToolTip {
	display:none;
	background:transparent url(/images/dialog/chsitooltip.png?n=1);
	background-repeat:no-repeat;
	font-size:12px;
	height:123px;
	width:310px;
	padding:0px;
	color:#fff;
}
.ToolTipText {
	margin:15px;
	overflow: hidden;
	height:90px;
	text-align:justify;
}
/* CSS Graveyard, if its here long enough without causing problems, we get rid of it.

deat 
*/
.AlignRight {
	float:right;
	padding-right:5px;
}
.CenterBox {
	width:450px;
	margin-left: auto;
	margin-right:auto;
}
/* 
  -- Even more rounded corners with CSS: Base stylesheet --
*/

.dialog {
	position:relative;
	margin:0px auto;
	min-width:8em;
	max-width:760px; /* based on image dimensions - not quite consistent with drip styles yet */
	color:#000;
	z-index:1;
	margin-left:30px; /* default, width of left corner */
	margin-bottom:0.5em; /* spacing under dialog */
}
.dialog .content, .dialog .t, .dialog .b, .dialog .b div {
	background:transparent url(/images/backgrounds/BackgroundShadowed.png) no-repeat top right;
	_background-image:url(/images/backgrounds/dialog2-blue.gif);
}
.dialog .content {
	position:relative;
	zoom:1;
	_overflow-y:hidden;
	padding:30px 32px 0px 0px;
}
.dialog .t {
	/* top+left vertical slice */
 position:absolute;
	left:0px;
	top:0px;
	width:30px; /* top slice width */
	margin-left:-30px;
	height:100%;
	_height:1600px; /* arbitrary long height, IE 6 */
	background-position:top left;
}
.dialog .b {
	/* bottom */
 position:relative;
	width:100%;
}
.dialog .b, .dialog .b div {
	height:30px; /* height of bottom cap/shade */
	font-size:1px;
}
.dialog .b {
	background-position:bottom right;
}
.dialog .b div {
	position:relative;
	width:30px; /* bottom corner width */
	margin-left:-30px;
	background-position:bottom left;
}
.dialog .hd, .dialog .bd, .dialog .ft {
	position:relative;
}
.dialog .wrapper {
	/* extra content protector - preventing vertical overflow (past background) */
 position:static;
	max-height:1000px;
	overflow:auto; /* note that overflow:auto causes a rather annoying redraw "lag" in Firefox 2, and may 

degrade performance. Might be worth trying without if you aren't worried about height/overflow issues. */
}
.dialog h1, .dialog p {
	margin:0px; /* margins will blow out backgrounds, leaving whitespace. */
	padding:0.5em 0px 0.5em 0px;
}
.dialog h1 {
	padding-bottom:0px;
}
.WingDingsElement {
	font-family: 'Wingdings 2'
}
/****************** DEFAULT ***************************/

.SideMenuBlock {
	background-image: url(/images/sidearc.png);
	min-height: 435px;
	width: 180px;
	padding-top: 20px;
	margin-bottom: 20px;
	z-index: 180;
	background-repeat:no-repeat;
}
.MenuOptions .MenuLink {
	background-image: url(/images/multiimage.png);
	background-position: -18px -91px;
	background-repeat:no-repeat;
	padding-left: 14px;
}
.bdaybox {
	background-image: url(/images/multiimage.png);
	background-position: 0px 0px;
	background-repeat:no-repeat;
	padding-left: 14px;
	width: 40px;
	height:52px;
}
.annivtext {
	background-image: url(/images/multiimage.png);
	background-position: 0px -52px;
	background-repeat:no-repeat;
	padding-left: 19px;
}
.task {
	background-image: url(/images/multiimage.png);
	width:15px;
	height:16px;
}
.taskmeeting {
	background-position: 2px -104px;
}
.taskreminder {
	background-position: -17px -104px;
}
.boxbottom {
	background-image: url(/images/multiimage.png);
	background-position: 0px -703px;
	height:10px;
	background-repeat:repeat-x;
}
.boxbottom .bbl {
	float: left;
	background-image: url(/images/multiimage.png);
	background-position: 0px -715px;
	width: 10px;
	background-repeat: no-repeat;
	height: 10px
}
.boxbottom .bbr {
	float: right;
	background-image: url(/images/multiimage.png);
	background-position: -9px -715px;
	width: 10px;
	background-repeat: no-repeat;
	height: 10px
}
.boxtr {
	background-image: url(/images/multiimage.png);
	background-position: -19px -715px;
	width: 10px;
	background-repeat: no-repeat;
	height: 10px
}
.boxRt {
	background-image: url(/images/pagebgright.jpg);
	width: 10px;
	background-repeat: repeat-y;
}
.MiniWindow {
	scrollbar-arrow-color: #c2c2c2;
	scrollbar-3dlight-color: #e0e0e0;
	scrollbar-darkshadow-color: #e0e0e0;
	scrollbar-face-color: #e0e0e0;
	scrollbar-highlight-color: #c2c2c2;
	scrollbar-shadow-color: #c2c2c2;
	scrollbar-track-color: #e0e0e0;
}
.defaultLeft {
}
/****************** NEW STUFF ***********************/

.button {
	width: 72px;
}
a.actionButton 
{

    border: 1px solid;
	color: #FFF;
	text-align: center;
	display: block;
	padding: 0px 5px;
	font-size: 9px;
	margin-right: 5px;
	margin-bottom: 3px;
	background-image: url(/images/action-button-bg.png);
	background-repeat: no-repeat;
	line-height: 25px;
	height: 25px;
	width: 105px;
}
a.actionButton:hover {
	text-decoration: none;
	cursor: pointer;	
	background-image: url(/images/action-button-bg-hover.png);
	background-repeat: no-repeat;
}
.loginDialog {
	width:450px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 40px;
}
.loginBox {
	margin-left: auto;
	margin-right: auto;
	text-align:center;
}
.loginLeft {
	width:150px;
	padding-top:20px;
}
.loginInput {
	height: 30px;
	font-size: 25px;
	width: 236px;
}
.loginMessage {
	z-index: 104;
	position: relative;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10pt;
}
.loginCheck {
	font-size: 10pt;
}
.buttonPanel {
	text-align:right;
}
.datasourceWrap {
	padding-left: 40px;
	margin-top: 80px;
}
.homeCal {
	z-index: 100;
	/* Width: 747px; */
	Height: 230px;
	/*left: 2px;
	top: 20px; */
	padding: 5px;
	padding-bottom: 0px;
}
.defaultWrap {
	padding-top: 30px;
}
.defCalPanel {
	z-index: 118;
	overflow: hidden;
	background-color: #f3f3f3;
	border: 1px solid #c3c3c3;
	width: 754px;

}
.defCalHeader {
	text-align: center;
	display: block;
	background-color: #E0E0E0;
	color: #333;
	font-weight: bold;
	padding: 2px 0px 2px 0px;
	margin-bottom: 3px;
	border-bottom: 1px #c3c3c3 solid;
}
.calDateHeader {
	background-color: #CCC;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: #666;
}
.defRecent {
	width: 250px;
	height:18px;
	overflow: hidden;
}
.topTabs {
	border-bottom:solid 1px #bbb8a9;
	height:25px;
}
.coHeaderMiddle {
	background-image: url(/images/navigation/stretchymiddle.PNG);
	background-repeat:repeat;
	height:32px;
}
.coHeaderRtLimiter {
	background-image: url(/images/navigation/rightlimiter.PNG);
	background-repeat:no-repeat;
	background-position:right;
	height:32px;
}
.statusWrap {
	border: inset 1px gray;
	margin-right:7px;
	padding: 1px;
	width: 100px;
}
.statusBar {
	background-color:#536c87;
}
.statusBarTxt {
	width:100px;
	text-align:center;
}
.ClientPageBody {
	font-size: 8pt;
}
.ClientPageBody .minilink {
	color: darkblue;
	font-weight: bold;
	font-size: 7pt;
}
.ClientPageBody a.minilink {
	text-decoration: underline;
}

.uxMobilePopup .buttonPanel .button {
	width: auto;
}


.modalClientSurveyDetailsArea, .modalUXLocationsTab {
	height:430px;
	overflow-x:hidden;
	overflow-y:auto;
}
.modalClientSurveyNotesArea {
	height:430px;
	overflow-x:hidden;
	overflow-y:auto;
}
.modalClientSurveyFilesArea {
	height:430px;
	overflow-x:hidden;
	overflow-y:auto;
}
.modalClientSurveyReportArea {
	height:430px;
	overflow-x:hidden;
	overflow-y:auto;
}
.modalClientSurveyEmailArea {
	height:430px;
	overflow-x:hidden;
	overflow-y:auto;
}
.modalClientSurveyQuestionsArea, .modalUXLocationsDemoTab {
	height:393px;
	overflow-x:hidden;
	overflow-y:auto;
}
.modalTPAdetails {
	height:430px;
	overflow-x:hidden;
	overflow-y:auto;
}
.modalContactView {
	height:460px;
	overflow-x:hidden;
	overflow-y:auto;
}
.modalAlertView {
	height:460px;
	overflow-x:hidden;
	overflow-y:auto;
}
/*.modalHeader {
	background-color:#526D84;
	height:35px;
	margin-left:0px;
	padding-left:0px;
	border: solid 1px black;
	font-size: 12px;
}*/
.modalHeaderInner {
	float:right;
	color:#FFF;
	padding-top:6px;
	padding-right:50px;
}
.modalBG {
	background-color: #FFF;
	padding: 5px;
}
.modalLocationHistory {
	height:218px;
	width:880px;
	overflow:auto;
}
.defRecentItem {
	padding:2px;
	font-size:9pt;
}
/**************User Calendar*******************/
     .ClientCalendarCell {
	height:82px;
	width:130px;
	border:solid 1px silver;
}
.ClientCalendarCellOutsideMonth {
	height:82px;
	width:130px;
	border:solid 1px silver;
	background-color:#E3E3E3;
}
.WeeklyClientCalendarCell {
	height:75px;
	width:100%;
	border:solid 1px silver;
}
.ClientCalendarHeader {
	background-color:silver;
	font-weight:bold;
}
.WeeklyClientCalendarCellToday {
	height:75px;
	width:100%;
	border:solid 1px orange;
}
.ClientCalendarCellToday {
	height:82px;
	width:130px;
	border:solid 1px orange;
}
.ClientCalendarHeaderToday {
	background-color:orange;
	font-weight:bold;
}
.ClientCalendarEvent {
	text-align:left;
	margin-top:2px;
	margin-bottom:2px;
	margin-left:2px;
	width:95%;
	font-size:8pt;
	overflow:hidden;
	height:15px;
}
.WeeklyClientCalendarEvent {
	margin-top:2px;
	margin-bottom:2px;
	margin-left:2px;
	width:97%;
	font-size:8pt;
	overflow:hidden;
	height:15px;
}
.ClientCalendarColor0 {
	background-color:#E1E9F5;
	border: solid 1px #B1B9C5;
}
.ClientCalendarColor1 {
	background-color:#EFA3A6;
	border: solid 1px #BF7376;
}
.ClientCalendarColor2 {
	background-color:#B4E3AE;
	border: solid 1px #84B37E;
}
.ClientCalendarColor3 {
	background-color:#FAF798;
	border: solid 1px #CAC768;
}
.ClientCalendarColor4 {
	background-color:#A8C0E8;
	border: solid 1px #7890B8;
}
.ClientCalendarColor5 {
	background-color:#F3BD97;
	border: solid 1px #C38D67;
}
.ClientCalendarColor6 {
	background-color:#BBAAE2;
	border: solid 1px #8B7AC2;
}
.ClientCalendarEventContainer {
	height:60px;
	overflow-y:auto;
	overflow-x:hidden;
}
#chaimsLossRunCharts {
	display: none;
}
.locationLeftListing {
	padding-bottom: 10px;
}
a.leftNavBtn {
	display:block;
	background-image:none;
	padding:0px 0px 0px 0px;
	margin:5px 0px 0px 0px;
}
.modalLocImageUpload {
	height:275px;
}
.modalLocImageUploadBox {
	height:275px;
	width:460px;
	background-color: #E4E4E4;
	border: 1px solid #CCC;
}
.ui-datepicker-trigger { vertical-align:  text-bottom; margin-bottom: -2px;}

#ContentPane {position: relative; }
/*Selector */
   .Selector {background-image: url(/images/backgrounds/selectorBG.png); background-position: bottom; height: 30px; border: solid 1px #c7c5b8; display: inline-block;  line-height: 30px;}
    .SelectorContainer { height: 30px; line-height: 30px; background-repeat: no-repeat; background-position:right;display: inline-block; position:relative; } 
    .SelectorContainerBG {height: 30px; background-position: 0px 1px; background-repeat: repeat-x;}
    .SelectorContainer a {padding: 0px 15px 0px 15px; height: 30px; display: inline-block; z-index: 10; position: relative; color: #4E6380; text-decoration: none; }
    .SelectorContainer a:hover {text-decoration: none; color: #333;}
    .SelectorWindow {background-color: #ccc; height: 30px; line-height: 30px; width: 100%; display:inline-block; position: absolute;}
    /* each block css class describes a different type of block or paragraph*/
div.blockIndent
{
    margin-left:10px;
}

 .spinner
        {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -50px; /* half width of the spinner gif */
            margin-top: -50px; /* half height of the spinner gif */
            text-align: center;
            z-index: 91234;
            overflow: auto;
            width: 100px; /* width of the spinner gif */
            height: 102px; /*hight of the spinner gif +2px to fix IE8 issue */
        }

  
     
 /*Modal v4 CSS*/
        .CHSIModal
        {
            display:none;
        }
        .CHSI-Modaloverlay
        {
                background-color:#eee;
        }
        .CHSIModal
        {
         font: Arial; text-align:left; background:#fff;  -webkit-box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.96);
-moz-box-shadow:    0px 0px 5px rgba(50, 50, 50, 0.96);
box-shadow:         0px 0px 5px rgba(50, 50, 50, 0.96);
-webkit-border-radius: 8px;
-moz-border-radius: 8px;
border-radius: 8px;
        }
        .CHSIModal .Modalheader
        {
            background-color: #4b6480; color:#FFFFFF;
            font-size:12px; line-height:15px; font-weight: bold; padding: 5px 10px 4px 12px;
                    -moz-border-radius-topright: 8px;
-webkit-border-top-right-radius: 8px;
border-top-right-radius: 8px;
-moz-border-radius-topleft: 8px;
-webkit-border-top-left-radius: 8px;
border-top-left-radius: 8px;
        }
        .CHSI-ModalClose
        {
            position: absolute; right:5px; top:5px; cursor:pointer;
        }
        .CHSI-ModalHelp
        {
            position: absolute; right:22px; top:5px; cursor:pointer;
        }
        .ModalStatusBar
        {
        background-color: #e5e5e5; position:absolute; bottom:0px; height:20px; width:100%;
        -moz-border-radius-bottomright: 8px;
-webkit-border-bottom-right-radius: 8px;
border-bottom-right-radius: 8px;
-moz-border-radius-bottomleft: 8px;
-webkit-border-bottom-left-radius: 8px;
border-bottom-left-radius: 8px;
        }

/*** Questionnaire -  /sig/underwriting/Form.aspx ***/
.HelpIcon
{
    cursor:pointer;
    margin-left: 5px;
}

/**** System Notifications ****/

#SystemNotification, .SystemNotification{
 /** Do at some point **/
}

#MiniSystemNotification, .MiniSystemNotification{    
    color: green;
    font-weight: bold;
    text-align: center;
    min-width: 50px;
    max-width: 200px;
    padding-top: 10px;
    height: 20px;
    position: absolute;
    bottom:0px;
    right:25%;
    left:57%;
    margin-left:-150px;
}

#BottomButtonContainer, .BottomButtonContainer {            
    position:absolute;
    bottom:0;
    width:100%;
    height:30px;                      
}
label, p, h1, h2, h3, h4, input, div, textarea, tbody{
    font-family: Arial !important;
}